import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  img {
    width: 100%;

    @media only screen and (max-width: 767px) {
      width: 60%;
      margin: auto;
      text-align: center;
      display: block;
    }
  }
`;

const CIContent = styled(Col)`
  margin: 30px 0 0 0 !important;
  text-align: left;

  h2 {
    font-size: 24px;
    font-weight: 300;
    color: #282828;
    line-height: 29px;
    margin: 0 0 30px 0;
  }

  h3 {
    margin: 0 0 15px 0;
    font-family: ProximaNova-Semibold;
    color: #282828;
    line-height: 29px;
    font-size: 20px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #282828;
  }

  @media screen and (max-width: 767px) {
    order: 1;
    text-align: center;
  }
`;

const AboutSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12} lg={10}>
            <Row>
              <CIContent
                md={6}
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-delay="400"
              >
                <h2>
                  CHIMP: Charitable Impact Foundation (Canada) is a registered
                  charity and public foundation which offers donor-advised
                  funds.
                </h2>
                <h3>What does that mean?</h3>
                <p>
                  In short, it means you can support all your favourite
                  charities and manage your charitable giving from a single
                  account, which we call the Impact Account.
                </p>
                <p>
                  When you add money to your Impact Account, you're actually
                  making a donation to CHIMP: Charitable Impact Foundation
                  (Canada)—a registered charity and public foundation. It's why
                  you get a tax receipt after adding money. The funds stay in
                  your account until you let us know that you'd like to send
                  charitable gifts to others, including charities, Giving
                  Groups, or friends and family on Charitable Impact.
                </p>
              </CIContent>

              <Col
                md={6}
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-delay="400"
              >
                <img src="/foundation/illustration.png" alt="" loading="lazy" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default AboutSection;
