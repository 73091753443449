import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section``;

const OurAdvisors = styled(Row)`
  border-radius: 4px;
  padding: 80px;
  margin: 0 0 115px 0;

  h1 {
    font-size: 30px;
    font-family: ProximaNova-Semibold;
    line-height: 37px;
    text-align: center;
    color: #282828;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #282828;
    margin: 0 0 50px 0;
    font-family: ProximaNova-Light;
  }

  @media only screen and (max-width: 767px) {
    padding: 100px 30px !important;
  }
`;

const OurAdivsorRightDot = styled.div`
  background-image: url(/foundation/our-adivsor-right-dot.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 228px;
  height: 285px;
  position: absolute;
  top: -67px;
  right: -81px;
  z-index: -1;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const OurAdvisorsLogo = styled(Col)`
  margin: 0 0 27px 0;
  text-align: center;
`;

const LogoAdvisor = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgb(0, 0, 0, 0.23);
`;

const OrangeDot = styled.div`
  background-color: #ed803b;
  width: 47px;
  height: 9px;
  margin: 10px 0 0 15px;
`;

const BlueDot = styled.div`
  background-color: #27ccd1;
  width: 18px;
  height: 9px;
  margin: 10px 0 0 4px;
`;

const AdvisorsSection = () => {
  return (
    <SectionWrap>
      <Container className="p-0">
        <OurAdvisors className="justify-content-md-center">
          <Col md={12} lg={7}>
            <Row>
              <Col md={12} lg={12}>
                <h1>Our advisors</h1>
                <h3 className="d-md-block">
                  Charitable Impact is audited by MNP and advised by some of the
                  best charity lawyers and strategists in Canada.
                </h3>
              </Col>
            </Row>
          </Col>
          <Col md={10} style={{ position: "relative" }}>
            <Row className="justify-content-center">
              <OurAdivsorRightDot />
              <OurAdvisorsLogo md={4}>
                <LogoAdvisor>
                  <img
                    src="/foundation/MNP_logo.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </LogoAdvisor>
              </OurAdvisorsLogo>
              <OurAdvisorsLogo md={4}>
                <LogoAdvisor>
                  <img
                    src="/foundation/nortonrose.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </LogoAdvisor>
              </OurAdvisorsLogo>
              <OurAdvisorsLogo md={4}>
                <LogoAdvisor>
                  <img
                    src="/foundation/gardiner.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </LogoAdvisor>
              </OurAdvisorsLogo>
              <OrangeDot className="d-none d-md-block" />
              <BlueDot className="d-none d-md-block" />
            </Row>
          </Col>
        </OurAdvisors>
      </Container>
    </SectionWrap>
  );
};

export default AdvisorsSection;
