import React from "react";
import AboutSection from "../components/Foundation/AboutSection";
import AccountableSection from "../components/Foundation/AccountableSection";
import AdvisorsSection from "../components/Foundation/AdvisorsSection";
import FBannerSection from "../components/Foundation/BannerSection";
import Layout from "../components/Layout/Layout";

const pageInfo = {
  parent_path: "/about",
  path: "/foundation",
  title: "The Foundation",
  description:
    "We're registered with the Canada Revenue Agency as CHIMP: Charitable Impact Foundation (Canada). See our audited financial statements and view our annual reports.",
};

const FoundationPage = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <FBannerSection />
      <AboutSection />
      <AccountableSection />
      <AdvisorsSection />
    </Layout>
  );
};

export default FoundationPage;
