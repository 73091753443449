import React, { useState } from "react";
import { Container, Row, Col, Form, Dropdown } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  margin-top: 70px;
  padding-bottom: 50px;
  text-align: center;
  background-color: #f5f8fc;
`;

const HeadingText = styled.h2`
  color: #6e6f72 !important;
  font-family: ProximaNova-Light !important;
  padding: 101px 0 15px 0;
  max-width: 520px;
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  font-size: 30px;
  line-height: 29px;
`;

const StyledH3 = styled.h3`
  margin: 0 0 70px 0;
  font-family: ProximaNova-Light;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #424242;
`;

const DocDownloadWrap = styled.div`
  margin: 0 0 33px 0;
`;

const StyledH2 = styled.h2`
  color: #282828;
  font-weight: 600 !important;
  font-family: ProximaNova-Light !important;
  font-size: 18px;
  line-height: 29px;
  margin: 0 0 12px 0;
  padding: 0;
  text-align: left;
  max-width: 520px;
`;

const StyledReportsH2 = styled(StyledH2)`
  @media only screen and (max-width: 1199px) {
    margin-bottom: 40px;
  }
`;

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledLi = styled.li`
  margin: 0 0 8px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dedede;
  float: left;
  width: 100%;
`;

const DocDownloadLeft = styled.span`
  padding: 16px 0 16px 18px;
  float: left;
  font-size: 12px;
  color: #282828;
`;

const DownloadBtn = styled.span`
  padding: 16px 18px 16px 19px;
  background-color: #055ce5;
  border-radius: 0 2px 2px 0;
  float: right;

  @media only screen and (max-width: 991px) {
    padding: 16px 6px;
  }

  &:hover {
    background-color: #0246b0;
  }
`;

const LinkIcon = styled(DownloadBtn)`
  background-color: transparent;

  @media only screen and (max-width: 991px) {
    padding: 16px 6px;
  }

  &:hover {
    background-color: transparent;
  }
`;

const Reports = styled.div`
  margin: 0 0 100px 0;
  text-align: left;
`;

const StyledDropdown = styled(Dropdown)`
  display: inline-block;
  vertical-align: middle;
  width: 80%;
  white-space: unset;

  .dropdown-toggle {
    white-space: unset;
    height: 55px;
    background-color: #fff;
    display: block;
    padding: 7px 30px 7px 10px;
    border: 1px solid #dedede;
    border-radius: 4px;
    position: relative;
    font-size: 12px;
    line-height: 39px;
    color: #282828;
    text-decoration: none;
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      border: solid #282828;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      width: 11px;
      height: 11px;
      right: 15px;
      top: -5px;
      bottom: 0;
      margin: auto;
      transition: all 0.3s ease-out;
    }

    @media screen and (max-width: 767px) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }

  &.show {
    & > a {
      background-color: #055ce5;
      color: #fff;
      &::after {
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg);
        border-color: #fff;
        top: 0;
      }
    }
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  width: 100%;
  height: auto;
  max-height: 192px !important;
  overflow-y: scroll !important;
  border-radius: 0 !important;
  //   top: -5px !important;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  font-size: 12px !important;
  white-space: unset !important;
  padding: 12px 30px 12px 10px !important;
`;

const ReportOptionsDropdown = [
  {
    text: "2023 Annual Report",
    url: "https://chimp-brand-assets.s3.amazonaws.com/financial/CharitableImpact-Annual-Report-2023.pdf",
  },
  {
    text: "2022 Annual Report",
    url: "https://chimp-brand-assets.s3.us-east-1.amazonaws.com/financial/CharitableImpact-Annual-Report-2022.pdf",
  },
  {
    text: "2021 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/CharitableImpact-Financial-Statements-2021.pdf",
  },
  {
    text: "2020 Annual Report",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/CharitableImpact-Annual-Report-2020.pdf",
  },
  {
    text: "2020 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/CharitableImpact-Financial-Statements-2020.pdf",
  },
  {
    text: "2019 Annual Report",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/CharitableImpact-Annual-Report-2019.pdf",
  },
  {
    text: "2019 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/CharitableImpact-Financial-Statements-2019.pdf",
  },
  {
    text: "2019 Management’s Discussion and Analysis",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/CharitableImpact-MD&A-2019.pdf",
  },
  {
    text: "2018 Annual Report, Audited Financial Statements, and MD&A",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_AuditedFinancialStatements_2018.pdf",
  },
  {
    text: "2017 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_AuditedFinancialStatements_2017.pdf",
  },
  {
    text: "2016 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_AuditedFinancialStatements_2016.pdf",
  },
  {
    text: "2015 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_AuditedFinancialStatements_2015.pdf",
  },
  {
    text: "2014 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_AuditedFinancialStatements_2014.pdf",
  },
  {
    text: "2013 Audited Financial Statements",
    url: "https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_AuditedFinancialStatements_2013.pdf",
  },
];

const AccountableSection = () => {
  const [textOption, setTextOption] = useState(ReportOptionsDropdown[0].text);
  const [linkTarget, setLinkTarget] = useState(ReportOptionsDropdown[0].url);

  function downloadFile(event: any) {
    event.preventDefault();

    setTextOption(event.target.text);
    setLinkTarget(event.target.href);
  }

  return (
    <SectionWrap>
      <Container>
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={12} lg={7}>
              <Row>
                <Col sm={12}>
                  <HeadingText>Accountable impact</HeadingText>
                  <StyledH3 style={{ marginBottom: "10px" }}>
                    We're registered with the Canada Revenue Agency as CHIMP:
                    Charitable Impact Foundation (Canada), business/registration
                    number{" "}
                    <strong style={{ fontWeight: "600" }}>
                      845528827RR0001.
                    </strong>
                  </StyledH3>
                  <StyledH3>
                    CHIMP: Charitable Impact Foundation (Canada)'s financial
                    year-end date is July 31.
                  </StyledH3>
                </Col>
              </Row>
            </Col>

            <Col md={12} lg={10}>
              <Row>
                <Col md={6}>
                  <DocDownloadWrap
                    data-sal="fade"
                    data-sal-delay="400"
                    data-sal-duration="1000"
                  >
                    <StyledH2>
                      The following documents are available for download:
                    </StyledH2>
                    <StyledUl>
                      <StyledLi>
                        <a
                          href="https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_Bylaws.pdf"
                          target="_blank"
                        >
                          <DocDownloadLeft>
                            Charitable Impact Foundation Bylaws.PDF{" "}
                          </DocDownloadLeft>
                          <DownloadBtn>
                            <img
                              src="/foundation/download-icon.png"
                              alt=""
                              loading="lazy"
                            />
                          </DownloadBtn>
                        </a>
                      </StyledLi>
                      <StyledLi>
                        <a
                          href="https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_CertificateOfContinuance.pdf"
                          target="_blank"
                        >
                          <DocDownloadLeft>
                            Certificate of Continuance.PDF{" "}
                          </DocDownloadLeft>
                          <DownloadBtn>
                            <img
                              src="/foundation/download-icon.png"
                              alt=""
                              loading="lazy"
                            />
                          </DownloadBtn>
                        </a>
                      </StyledLi>
                      <StyledLi>
                        <a
                          href="https://s3.amazonaws.com/chimp-brand-assets/financial/ChimpFoundation_LettersPatent.pdf"
                          target="_blank"
                        >
                          <DocDownloadLeft>
                            Letters Patent 170.PDF{" "}
                          </DocDownloadLeft>
                          <DownloadBtn>
                            <img
                              src="/foundation/download-icon.png"
                              alt=""
                              loading="lazy"
                            />
                          </DownloadBtn>
                        </a>
                      </StyledLi>
                      <StyledLi>
                        <a
                          href="https://apps.cra-arc.gc.ca/ebci/hacc/srch/pub/dsplyQckVw?selectedCharityId=124349765&selectedCharityBn=845528827RR0001"
                          target="_blank"
                        >
                          <DocDownloadLeft>
                            T3010: Canada Revenue Agency Filings{" "}
                          </DocDownloadLeft>
                          <LinkIcon>
                            <img
                              src="/foundation/link-icon.png"
                              alt=""
                              loading="lazy"
                            />
                          </LinkIcon>
                        </a>
                      </StyledLi>
                    </StyledUl>
                  </DocDownloadWrap>
                </Col>

                <Col md={6} className="ps-md-0">
                  <Reports
                    data-sal="fade"
                    data-sal-delay="400"
                    data-sal-duration="1000"
                  >
                    <StyledReportsH2>Reports and statements:</StyledReportsH2>
                    <Form>
                      <DropdownWrapper>
                        <StyledDropdown className="flex-fill">
                          <Dropdown.Toggle as="a" id="dropdownMenuButton">
                            {textOption}
                          </Dropdown.Toggle>

                          <StyledDropdownMenu>
                            {ReportOptionsDropdown.map((items) => {
                              return (
                                <StyledDropdownItem
                                  key={items.text}
                                  href={items.url}
                                  onClick={downloadFile}
                                >
                                  {items.text}
                                </StyledDropdownItem>
                              );
                            })}

                            <input
                              type="hidden"
                              id="selectReport"
                              value={linkTarget}
                            />
                          </StyledDropdownMenu>
                        </StyledDropdown>
                        <DownloadBtn
                          onClick={() => window.open(linkTarget)}
                          data-sal="fade"
                          data-sal-duration="1000"
                          data-sal-delay="400"
                        >
                          <img
                            src="/foundation/download-icon.png"
                            alt=""
                            loading="lazy"
                          />
                        </DownloadBtn>
                      </DropdownWrapper>
                    </Form>
                  </Reports>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </SectionWrap>
  );
};

export default AccountableSection;
