import React, { useEffect, useState } from "react";
import styled from "styled-components";

const BannerSection = styled.section`
  position: relative;
  min-height: 400px;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    min-height: 408px;
    padding: 0 22px;
    margin: 0 0 60px 0;
  }

  @media only screen and (max-width: 420px) {
    min-height: 448px;
  }
`;

const BannerContent = styled.div`
  display: block;
  text-align: center;
  margin: auto;
  margin-top: 110px;
  padding: 1rem;
  position: relative;
  z-index: 999;
  width: 520px;

  @media only screen and (max-width: 991px) {
    width: 360px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 73px;
    padding: 0;
  }

  @media only screen and (max-width: 420px) {
    width: 250px;
  }

  h1 {
    color: #424242;

    @media only screen and (max-width: 991px) {
      font-size: 30px;
    }

    @media only screen and (max-width: 767px) {
      color: #424242;
      font-weight: 300;
      font-size: 30px;
      line-height: 37px;
      font-family: ProximaNova-Light;
    }
  }
`;

const TileImages = styled.div`
  position: absolute;
  top: -61px;
  background-position: center !important;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  height: 520px;
  width: 50%;
  z-index: 1;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  &.left {
    top: 20%;
    left: 0;
    text-align: left;
    z-index: 0;
  }

  &.right {
    top: 0;
    right: 0;
    text-align: right;
  }
`;

const MobileImgBanner = styled.img`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const SmallDot1 = styled.span`
  padding: 6px 7px 6px 8px;
  background-color: #db0163;
  position: absolute;
  left: 260px;
  bottom: 175px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const VTop = styled.div`
  vertical-align: bottom;

  span {
    vertical-align: top;
    display: inline-block;
  }
`;

const BgBanner1 = styled.span`
  position: absolute;
  left: 5%;
  top: -32%;
  text-align: right;
`;

const FundBannerImg2 = styled.img`
  position: absolute;
  left: 218px;
  top: -95px;
`;

const FundBannerImg3 = styled.img`
  position: relative;
  left: -8px;

  @media only screen and (max-width: 991px) {
    position: absolute;
    left: 36%;
    top: 0;
    width: 33%;
    height: 239px;
    -object-fit: cover;
    -o-object-fit: cover;
    object-fit: cover;
  }
`;

const FundBannerImg4 = styled.img`
  position: relative;
  top: -51px;
`;

const YellowDotC = styled.span`
  height: 92px;
  width: 82px;
  -o-object-fit: cover;
  object-fit: cover;

  @media only screen and (max-width: 1199px) {
    height: 52px;
    width: 60px;
    margin-left: 48px;
  }
`;

const YellowDot = styled.span`
  padding: 6px 7px 6px 8px;
  background-color: #f7ba4a;
  position: absolute;
  left: -131px;
  bottom: 361px;
`;

const M_TileIMages = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const M_TileImage1 = styled.span`
  display: inline-block;
  img {
    width: 65px;
    height: 230px;
    -o-object-fit: cover;
    object-fit: cover;
  }
`;

const M_BlueLine = styled.span`
  display: inline-block;
  width: 20px;
  height: 230px;
  background-color: #055ce5;
  margin-left: 50px;
`;

const M_TileImage2 = styled.span`
  display: inline-block;
  img {
    width: 89px;
    height: 230px;
    -o-object-fit: cover;
    object-fit: cover;
  }
`;

const M_Red_Line = styled.span`
  width: 18px;
  height: 230px;
  display: inline-block;
  background-color: #c799d1;
`;

const M_Light_Line = styled.span`
  width: 10px;
  height: 230px;
  display: inline-block;
  background-color: #db0163;
`;

const FBannerSection = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <BannerSection className="d-md-block">
      <TileImages style={{ transform: `translateY(-${offsetY * 2}px)` }}>
        <span>
          <MobileImgBanner
            src="/foundation/photo-1.png"
            className="d-md-block animate__animated animate__fadeInRight animate__slow animate__delay-1s"
            alt=""
          />
          <SmallDot1 className="d-md-block" />
        </span>
      </TileImages>

      <TileImages
        className="left"
        style={{ transform: `translateY(-${offsetY * 2}px)` }}
      >
        <VTop>
          <BgBanner1>
            <img
              src="/foundation/charitable-shape-left.png"
              className="d-md-block animate__animated animate__fadeInRight animate__slow animate__delay-1s"
              alt=""
              loading="lazy"
            />
          </BgBanner1>
          <span>
            <FundBannerImg2
              src="/foundation/bar-1.png"
              className="d-md-block animate__animated animate__fadeInRight animate__slow animate__delay-1s"
              alt=""
              loading="lazy"
            />
          </span>
        </VTop>
      </TileImages>

      <TileImages
        className="right"
        style={{ transform: `translateY(-${offsetY * 2}px)` }}
      >
        <VTop style={{ position: "relative" }}>
          <YellowDotC>
            <YellowDot />
          </YellowDotC>
          <span className="pe-1">
            <FundBannerImg3
              src="/foundation/photo-2.png"
              className="d-md-block animate__animated animate__fadeInRight animate__slow animate__delay-1s"
            />
          </span>
          <span>
            <FundBannerImg4
              src="/foundation/photo-3.png"
              className="d-md-block animate__animated animate__fadeInRight animate__slow animate__delay-1s"
            />
          </span>
        </VTop>
      </TileImages>

      {/* Banner Content */}
      <BannerContent id="charitableBannerContent">
        <h1>CHIMP: Charitable Impact Foundation (Canada)</h1>
      </BannerContent>

      {/* Tileimages for mobile */}

      <M_TileIMages>
        <M_TileImage1>
          <img
            src="/foundation/photo-1.png"
            className="animate__animated animate__fadeInRight animate__slow animate__delay-1s"
            alt=""
            loading="lazy"
          />
        </M_TileImage1>
        <M_BlueLine />
        <M_TileImage2 className="ps-3">
          <img
            src="/foundation/photo-2.png"
            className="animate__animated animate__fadeInRight animate__slow animate__delay-1s"
            alt=""
            loading="lazy"
          />
        </M_TileImage2>
        <M_Red_Line className="ms-2" />
        <M_Light_Line className="ms-2" />
      </M_TileIMages>
    </BannerSection>
  );
};

export default FBannerSection;
